import * as Sentry from "@sentry/nuxt";

const { sentry, isStaging } = useRuntimeConfig().public;

Sentry.init({
  environment: import.meta.dev
    ? "development"
    : isStaging
    ? "staging"
    : "production",
  dsn: sentry.dsn,
  integrations: [Sentry.replayIntegration()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
});
